import React,{useState,useEffect} from 'react'
import NextQuestionCta from './next-question-cta'
import CompanyLifeCycle from '../../blog/company-lifecycle'
import smile from '../../assets/images/icons/smile_40x40.gif'
import worry from '../../assets/images/icons/worry_40x40.gif'
const QuestionSlide1 = ({activeSection,setActiveSection,show}) =>{
    const windowGlobal = typeof window !== 'undefined' && window
    const [lstatus, setLstatus] = useState(false)
    const [scrolled, setScrolled] = useState(false)
    useEffect(()=>{
        if(show && !scrolled){
            if(window){
                window.scrollTo(0,0)
                setScrolled(true)
            }
        }
        if(!show){
            setScrolled(false)
        }
    },[lstatus,show])
    return(
            <div className={`question-slide-1 question-slide`} style={{display:show ? `block` : `none`}}>
            <div className="wrapper">
            <div className="listing-header align-center">
            <div className="small-wrapper">
            {!lstatus
            ? 
            <p className="default">
            <label>
            <img src={worry} alt="worry"/>
            </label>
            OMG we just payed more than 10$ to our friend Google to get you here. Could you get us some answers in return? It's just a couple of clicks more. Please{' '} 
            <strong className="green-box">
            <strong className="home-slide3">let us know</strong> 
            <span> 
            <svg xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 245 100"> 
            <polygon fill="#66C293" points="0,0 245.4,12.5 236.4,100.6 0,100.6 "></polygon> </svg> 
            </span> 
            </strong> your{' '}
            <span className="green-box"> 
            <strong className="home-slide3">company life cycle stage:</strong> 
            <span> 
            <svg xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 587 111"> 
            <polygon fill="#66C293" points="2,0 587.2,2.2 567.2,111.6 0,105.8 "></polygon> </svg> 
            </span> 
            </span>
            </p>
            :
            <p className="after_selected">
            <label>
            <img src={smile} alt="smile"/>
            </label>
            Oh, thank you !<br/>Would you be so kind to go to the{' '}<strong className="green-box">
            <strong className="home-slide3">next question</strong> 
            <span> 
            <svg xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 245 100"> 
            <polygon fill="#66C293" points="0,0 245.4,12.5 236.4,100.6 0,100.6 ">
            </polygon> 
            </svg> 
            </span> 
            </strong>{`?`}
            </p>}
            </div>
            </div>

            <CompanyLifeCycle className="category-filter-section" setLstatus={setLstatus}/>
            <NextQuestionCta
            btnstatus={lstatus}
            onClick={()=>{if(lstatus){setActiveSection(activeSection+1)}}}
            btntext={`Next question`}
            nothnxtext={`No thanks, our company is not doing anything`}
            nothanksredirect={`https://jdsolutions.typeform.com/to/viiuK9`}
            />
            </div>
            </div>
    )
}
export default QuestionSlide1