import React,{useState, useEffect} from 'react'
import {ElementWrap} from '../../blog/section2'
import {roleDatafunction} from '../../data_functions'
import NextQuestionCta from './next-question-cta'
import blush from '../../assets/images/icons/blush_40x40.gif'
import stareyes from '../../assets/images/icons/stareyes_40x40.gif'
const QuestionSlide2 = ({activeSection, setActiveSection, show}) =>{
    const [roleData,setRoleData]=useState(roleDatafunction)
    const [scrolled,setScrolled]=useState(false)
    const [rstatus,setRstatus]=useState(false)
    useEffect(()=>{
        if(roleData){
            setRstatus(roleDatafunction().filter(el=>el.checked === true).length !== 0 ? true : false)
        }
        if(show && !scrolled){
            if(window){
                window.scrollTo(0,0)
                setScrolled(true)
            }
        }
        if(!show){
            setScrolled(false)
        }
    },[roleData, rstatus,show])
    
    return(
        <div className={`question-slide-2 question-slide`} style={{display:show ? `block` : `none`}}>
        <div className="wrapper">
        <div className="listing-header align-center">
        <div className="small-wrapper" style={{minHeight:`182px`}}>
        <a href="#" className="slide-back" onClick={()=>setActiveSection(activeSection -1)}>
            <i className="fas fa-chevron-left"></i> Previous question</a>
        {!rstatus ? <p className="default dev_box_wrap">
        <label>
        <img src={blush} alt="blush"/>
        </label>
        Now we are very curious to understand your role in your company while searching for 
        <span className="dev_box"><strong> developers.</strong></span> Please{' '} 
        <strong className="green-box"><strong className="home-slide3">let us know</strong><span> 
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 100" 
        xmlnsXlink="http://www.w3.org/1999/xlink"> 
        <polygon fill="#66C293" points="0,0 245.4,12.5 236.4,100.6 0,100.6 "></polygon> 
        </svg> 
        </span> 
        </strong>{' '}your{' '}<strong className="green-box">
        <strong className="home-slide3">company role:</strong> 
        <span> 
        <svg xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 245 100" xmlnsXlink="http://www.w3.org/1999/xlink"> 
        <polygon fill="#66C293" points="0,0 245.4,12.5 236.4,100.6 0,100.6 "></polygon> 
        </svg> 
        </span> 
        </strong>

        </p>
        :
        <p className="after_selected"><label>
        <img src={stareyes} alt="smirk"/>
        </label>Awesome! Looks like Google can be a matchmaker !</p>}
        </div>
        </div>

        <div className="persona-filter">
        <ElementWrap 
        roleData={roleData}
        setRoleData={setRoleData}/>
        </div>
        <NextQuestionCta
            btnstatus = {rstatus}
            onClick={()=>{if(rstatus){setActiveSection(activeSection +1)}}}
            btntext = {`Next question`}
            nothanksredirect={`https://jdsolutions.typeform.com/to/CL0YBd`}
            nothnxtext = {`Sorry, I don't have any of those roles`}/>
        </div>
        </div>
    )
}
export default QuestionSlide2