import React from 'react'
import {Link} from 'gatsby'
import Logo from '../../assets/images/logo'
import './style.scss'
import Helmet from 'react-helmet'
const LandingHeader = ({fixed,bgcolor,children,className}) =>{
return(
    <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
        <Helmet defer={false}>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"/>
        </Helmet>
    <div className="row-fluid ">
    <div 
    className={`span12 widget-span widget-type-cell custom-header-wrapper ${className} ${fixed ? `formal` : null}`} 
    style={{backgroundColor:`${bgcolor ? bgcolor : `inherit`}`}}>
    <div className="row-fluid-wrapper row-depth-1 row-number-8 ">
    <div className="row-fluid ">
    <div className="span12 widget-span widget-type-raw_html landing-page-logo">
    <div className="cell-wrapper layout-widget-wrapper">
    <Link to='/' className="logo landing-logo-svg ">
    <Logo/>
    </Link>
    {children}
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
)
}
export default LandingHeader