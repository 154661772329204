import React,{useState, useEffect} from 'react'
import Header from '../header'
import './style.scss'
import QuestionSlide1 from './question-slide1'
import QuestionSlide2 from './question-slide2'
import QuestionSlide3 from './question-slide3'
import QuestionSlide4 from './question-slide4'
import Helmet from 'react-helmet'
import {navigate, withPrefix} from 'gatsby'
import queryString from 'query-string'
const EmailCatcherLandingPage = ()=>{
    const [expdata,setExpdata] = useState('')
    const[activeSection, setActiveSection] = useState(0)
  const windowGlobal = typeof window !== 'undefined' && window
  useEffect(()=>{
if(window){
    const x = queryString.parse(window.location.search).exp
    if(x){
      localStorage.setItem('expert_name',x)
      navigate('/about-you')
    }
    if(localStorage.getItem('expert_name')){
        const t=localStorage.getItem('expert_name')
        setExpdata(t)
    }
}
  },[activeSection])
    return(
            <div className="email-catcher-lp fp-viewing-home">
                <Header bgcolor="#eeeeee">
                <span className="c-header-right-desc" >
                  <Helmet defer={false}>
                    <title>About You | JDSolutions</title>
                    <link
                        rel="icon"
                        type="image/png"
                        href={`${withPrefix('/')}img/favicon-16x16.png`}
                        sizes="16x16"
                      />
                <meta property="og:title" content={`About You | JDSolutions`} />
                <meta property="og:type" content="page" />
                {/* <meta property="og:image" content={bigbanner} />  */}
                  </Helmet>
                    <h3>Stop wasting a lot of time searching for 
                    <span className="dev_box">
                        <strong>{expdata ? ` ${expdata}`:``} developers </strong>
                    </span>
                    </h3>
                    <p>We are a web agency with top-notch 
                    <span className="dev_box">
                    <strong> developers </strong>
                    </span> ready to help you!</p>
                    </span>  
                </Header>
                <div className="body-container container-fluid">
                {<QuestionSlide1 show={activeSection === 0} activeSection={activeSection} setActiveSection={setActiveSection}/>}
                {<QuestionSlide2 show={activeSection === 1} activeSection={activeSection} setActiveSection={setActiveSection}/>}
                {<QuestionSlide3 show={activeSection === 2} activeSection={activeSection} setActiveSection={setActiveSection}/>}
                {<QuestionSlide4 show={activeSection === 3} activeSection={activeSection} setActiveSection={setActiveSection}/>}
                </div>
            </div>    
    )
}
export default EmailCatcherLandingPage;