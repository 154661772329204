import React,{useState,useEffect,useRef} from 'react'
import NextQuestionCta from './next-question-cta'
import { navigate } from 'gatsby'
import {getLocationData} from '../../location_data'
import happyface from '../../assets/images/icons/happyface_40x40.gif'
import devil from '../../assets/images/icons/devil_40x40.gif'
import { isMobile } from 'react-device-detect'
const QuestionSlide4 = ({activeSection, setActiveSection, show}) =>{
  const windowGlobal = typeof window !== 'undefined' && window
    const [dataupdated, setDataupdated] = useState(false)
    const [scrolled, setScrolled] = useState(false)
    const xr1 = useRef(null)
    const [isEmail,setIsEmail] = useState(false)
    const [email,setEmail] = useState(``)
    const [subscription,setSubscription] = useState({blog:true,marketing:true,otoemail:true})
    const re = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    const handleChange=(e)=>{       
        setIsEmail(re.test(e.target.value))
        setEmail(e.target.value)
    }
    const encode = (data) => {
      return Object.keys(data)
          .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
          .join("&");
    }
    const handleSubscription = (e) =>{
          let olddd = subscription
          olddd[e.target.value] = !olddd[e.target.value]
          setSubscription({...olddd})
          if(window){
            localStorage.setItem('subscription',JSON.stringify(olddd))
          
          }
    }
const handleKeySubmit=(e)=>{
 
  if(e.key === 'Enter'){
     e.preventDefault()
    handleRedirect()
  }
}
    const handleRedirect = () =>{
      const userrole = []
      let filtereddata = []
      const lifecycledata = []
      let phone = null
      let expert = null
      let frontend = []
      let backend = []
      let database = []
      let version_control = []
      let server_hosting = []
      let project_management = []
      let blog_s = subscription.blog
      let marketing_s = subscription.marketing
      let otoemail_s = subscription.otoemail
      if(window){
      
        if(localStorage.getItem('roleData')){
            const t = JSON.parse(localStorage.getItem('roleData'))
            t.forEach(el=>{
                if(el.checked === true){
                    userrole.push(el.role)
                }
            })
        }
         if(localStorage.getItem('lifecycledata')){
            const s = JSON.parse(localStorage.getItem('lifecycledata'))
            s.forEach(el=>{
                if(el.checked === true){
                    lifecycledata.push(el.name)
                }
            })
        }
         
         if(localStorage.getItem('number')){
          phone = localStorage.getItem('number')
        }
         if(localStorage.getItem('expert_name')){
          expert = localStorage.getItem('expert_name')
        }

        if(localStorage.getItem('techstackdata')){
         let datatty = JSON.parse(localStorage.getItem('techstackdata'))
            datatty.forEach((el,i) =>{
              const filtered = el.listdata.filter(element=> element.selected === true)
              if(i === 0) {
               frontend = filtered.map((elm) => elm.itemname)   
              }
              if(i === 1){
                  backend = filtered.map((elm) => elm.itemname)    
              }
              if(i === 2){
                  database = filtered.map((elm) => elm.itemname)   
              }
              if(i === 3){
                  version_control = filtered.map((elm) => elm.itemname)   
              }
              if(i === 4){
                  server_hosting = filtered.map((elm) => elm.itemname)   
              }
              if(i === 5){
                  project_management = filtered.map((elm) => elm.itemname)   
              }
           
          })
                datatty.forEach(dt =>{
                  if(dt.selected){
                  filtereddata.push(dt.listname)
                  }
              dt.listdata.forEach(el =>{
                  if(el.selected === true){
                      filtereddata.push(el.itemname)
                  }
              })
              
          })
        }
    }



      if(isEmail){      
      fetch("/", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded"},
        body: encode({"form-name": 'Email catcher form', 
                      "User_roles":userrole.length !== 0 ? `${userrole.join(", ")}` : null,
                      "Company_lifecycle":lifecycledata.length !== 0 ? `${lifecycledata.join(", ")}` : null,
                      "Stack-Frontend":frontend.length !==0 ? `${frontend.join(", ")}`: null,
                      "Stack-Backend":backend.length !==0 ? `${backend.join(", ")}`: null,
                      "Stack-Database":database.length !==0 ? `${database.join(", ")}`: null,
                      "Stack-Version_control":version_control.length !==0 ? `${version_control.join(", ")}`: null,
                      "Stack-Server_hosting":server_hosting.length !==0 ? `${server_hosting.join(", ")}`: null,
                      "Stack-Project_management":project_management.length !==0 ? `${project_management.join(", ")}`: null,
                      "Blog subscription": blog_s ? 'subscribed' : 'not subscribed',
                      "Marketing subscription":marketing_s ? 'subscribed' : 'not subscribed',
                      "One to one email subscription": otoemail_s ? 'subscribed' : 'not subscribed',
                      "Email":email,
                      "Phone":phone,
                      "Expertise":expert,
                      "Language":getLocationData('languages') !== null ? getLocationData('languages').split(',')[0] : null,
                      "Country":getLocationData('country_name'),
                      "Zip":getLocationData('zipcode'),
                      "User_country":getLocationData('country_name'),
                      "City":getLocationData('city'),
                      "Latitude":getLocationData('latitude'),
                      "Longitude":getLocationData('longitude'),
                      "State":getLocationData('state_prov'),
                    })
      })
        .then(() => {
          if(window){
            localStorage.setItem('email',email)
            if(frontend.length !==0 ||
              backend.length !==0 ||
              database.length !==0 ||
              version_control.length !==0 ||
              server_hosting.length !==0 ||
              project_management.length !==0 ){
            localStorage.setItem('techstackpopupintracted',`true`)}
          }           
            navigate('/')
        })
        .catch(error => alert(error)); 
      }
    }
    useEffect(()=>{
      if(window){
        if(localStorage.getItem('email') && email === ``){
         const lemail = localStorage.getItem('email')
         setIsEmail(re.test(lemail))
         setEmail(lemail)
        }
        if(show){
          if(windowGlobal.innerWidth > 1023 && !isMobile){
          setTimeout(() => {
            xr1.current.focus()
          }, 100);}
          if(show && !scrolled){
            window.scrollTo(0,0)
            setScrolled(true)
            }
            if(!show){
              setScrolled(false)
          }
        }

        if(localStorage.getItem('subscription') && !dataupdated){
          let lsubs = JSON.parse(localStorage.getItem('subscription'))
          setSubscription({...lsubs})
          setDataupdated(true)
        }
      }
    },[isEmail,subscription,show])
    return(
<div className={`question-slide-4 question-slide`} style={{display:show ? `block` : `none`}}>
  <div className="wrapper">
    <div className="listing-header align-center">

                  <form hidden
                    name="Email catcher form"
                    method="POST"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    > 
                    <input type="hidden" name="form-name" value="Email catcher form" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" />
                    </label>
                    </p>  
                    <input name="User_roles" />
                    <input name="Company_lifecycle" />
                    <input name="Stack-Frontend" /> 
                    <input name="Stack-Backend" /> 
                    <input name="Stack-Database" /> 
                    <input name="Stack-Version_control" /> 
                    <input name="Stack-Server_hosting" /> 
                    <input name="Stack-Project_management" /> 
                    <input name="Blog subscription" /> 
                    <input name="Marketing subscription"/> 
                    <input name="One to one email subscription" /> 
                    <input name="Email" /> 
                    <input name="Phone" />                  
                    <input name="Expertise" />                   
                    <input name="Language" />
                    <input name="Country" />
                    <input name="Zip" />
                    <input name="User_country" />
                    <input name="City" />
                    <input name="Latitude" />
                    <input name="Longitude" />
                    <input name="State" />
               
                </form>                
      <div className="small-wrapper" style={{minHeight: `182px`}}>
        <a href="#" className="slide-back" onClick={()=>setActiveSection(activeSection -1)}>
          <i className="fas fa-chevron-left"></i> Previous question</a>
        <p className="default"><label>
            <img src={happyface} alt="happy face"/>
        </label>Great, you've answered 3 of our questions. Let's make things official! We would love to{' '}
        <strong className="green-box">
            <strong className="home-slide3">create a relationship</strong> 
        <span> 
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 100" xmlnsXlink="http://www.w3.org/1999/xlink"> 
        <polygon fill="#66C293" points="0,0 245.4,12.5 236.4,100.6 0,100.6 "></polygon> 
        </svg> 
        </span> 
        </strong>{' '}with you. To make our relationship work{' '}
        <strong className="green-box"><strong className="home-slide3">we need</strong> 
        <span> 
        <svg xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 245 100" xmlnsXlink="http://www.w3.org/1999/xlink"> 
        <polygon fill="#66C293" points="0,0 245.4,12.5 236.4,100.6 0,100.6 "></polygon> 
        </svg> </span> 
        </strong>{' '}
        your e-mail. So please add your e-mail and click continue below to accept{' '}
        <strong className="green-box">
            <strong className="home-slide3">
                JDSolutions
            </strong> 
        <span> 
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 100" xmlnsXlink="http://www.w3.org/1999/xlink"> 
        <polygon fill="#66C293" points="0,0 245.4,12.5 236.4,100.6 0,100.6 "></polygon> 
        </svg> 
        </span> 
        </strong>{' '}in your network:
        </p>
        
      </div>
    </div>
  
    <div className="visit-website-form">
      <form action="#" id="catcher_lp_form" method="post">
        <div className="custom-input">
          <input 
          type="email" 
          className="custom-email" 
          name="email_cthr" 
          value={email}
          ref={xr1}
          onKeyDown={handleKeySubmit}
          placeholder="Your e-mail address" 
          onChange={handleChange}
          required={true}/>
        </div>
        <div className="custom-input">
          <div className="chekbox-label-wrap">
            <input 
            id="enable_subscription" 
            name="customsub" 
            checked={subscription.blog}
            onChange={handleSubscription}
            value="blog" 
            type="checkbox" 
            style={{display:'none'}}/>
            <label for="enable_subscription"></label> 
            <div className="checkbox-desc">Blog Subscription 
            <span className="help-text">Receive timely updates with the latest blog posts</span>
            </div>
          </div>
        </div>
        <div className="custom-input">
          <div className="chekbox-label-wrap">
            <input 
            id="enable_marketing" 
            name="customsub" 
            value="marketing" 
            onChange={handleSubscription}
            checked={subscription.marketing}
            type="checkbox" 
            style={{display: `none`}}/>
            <label 
            for="enable_marketing">
            </label> 
            <div className="checkbox-desc">
                Marketing Information 
            <span className="help-text">
                Marketing offers and updates
            </span>
            </div>
          </div>
        </div>
        <div className="custom-input">
          <div className="chekbox-label-wrap">
            <input 
            id="enable_one_to_one" 
            name="customsub" 
            onChange={handleSubscription}
            checked={subscription.otoemail}
            value="otoemail" 
            type="checkbox" 
            style={{display: `none`}}/>
            <label for="enable_one_to_one">
                </label> 
                <div className="checkbox-desc"
                >One to One
                <span className="help-text">
                    One to one emails</span>
            </div>
            </div>
        </div>
      <NextQuestionCta 
      btntext={`Continue to our site`} 
      onClick={handleRedirect}
      btnstatus={isEmail}
      nothnxicon={devil}
      nothanksredirect={`https://jdsolutions.typeform.com/to/j1LQyj`}
      nothnxtext={`I don't want to give my email.`}/>
        </form>
          </div>
        </div>
        </div>
  

    )
}
export default QuestionSlide4