import React from 'react'
import owner from '../../components/assets/images/role_images/company_owner.svg'
import designer from '../../components/assets/images/role_images/designer.svg'
import developer from '../../components/assets/images/role_images/developer.svg'
import p_manager from '../../components/assets/images/role_images/project_manager.svg'
const roleDatafunction = () =>{
    const windowGlobal = typeof window !== 'undefined' && window
    const dt = [{
      role:'Company owner',
      checked:false,
      image:owner,
      tag:`owner`
      },
      {
      role:'Project manager',
      checked:false,
      image:p_manager,
      tag:`manager`
      },
      {
      role:'Developer',
      checked:false,
      image:developer,
      tag:`developer`
      },
      {
      role:'Designer',
      checked:false,
      image:designer,
      tag:`designer`
          }]
    if(windowGlobal){
      return windowGlobal.localStorage.getItem('roleData') ? JSON.parse(windowGlobal.localStorage.getItem('roleData')) : dt
    }else{
      return dt
    }
    
  }
  export {roleDatafunction}