import React,{useEffect} from 'react'
const ElementWrap=({roleData,setRoleData})=>{
    useEffect(()=>{

    },[roleData]);

    const handleCheck=(t)=>{
        let d=roleData
        const c=d[t].checked
        d[t].checked = !c
        setRoleData([...d])
        localStorage.setItem('roleData',JSON.stringify(d))

    };
         
    return( <div className="elem-wrap">
    <div className="testing">
    {roleData.map((dt,i)=>{
    return(<div key={i} className={`switch_box switch_boxcustom14 box_1 filter-1 is-checked ${dt.checked ? `check`:``}`} onClick={()=>handleCheck(i)}>
    <img src={dt.image} alt="role_image"/>
    <input type="checkbox" checked={dt.checked} className="switch_1"/>
    <label></label>
    <p>{dt.role}</p>
    </div>)
            })}
            </div>
        </div>)
}


export {ElementWrap};