import React,{useState,useEffect} from 'react'
import bulb from '../assets/images/company_lifecycle/bulb.svg'
import flag from '../assets/images/company_lifecycle/flag.svg'
import growth from '../assets/images/company_lifecycle/growth.svg'
import launcher from '../assets/images/company_lifecycle/launcher.svg'
import watch from '../assets/images/company_lifecycle/watch.svg'
import '../assets/styles/bootstrap-slider.scss'

const CompanyLifeCycle=({className, setLstatus, setLifecyclevalue})=>{
    const windowGlobal = typeof window !== 'undefined' && window
    const dt = [
        {
            name:`Start something new`,
            desc:`Find your big (next) idea. And begin now!`,
            img:flag,
            checked:false
        },
        {
            name:`Create things`,
            desc:`Validating your ideas and creating your first proof of concept`,
            img:bulb,
            checked:false
        },
        {
            name:`Launch your things`,
            desc:`Everything about getting your product to market`,
            img:launcher,
            checked:false
        },
        {
            name:`Focus on growth`,
            desc:`Everything about scaling up your product with your customer base`,
            img:growth,
            checked:false
        },
        {
            name:`Optimise your work`,
            desc:`Get more value out of your business`,
            img:watch,
            checked:false
        }
    ]
   const HandleData = () =>{
       if(windowGlobal){
        if(localStorage.getItem('lifecycledata')){
            let pdt = JSON.parse(localStorage.getItem('lifecycledata'))
            return pdt
        }else return dt
       }else return dt
   }
    const [sliderval,setSliderval]=useState(0)
    const [lifecycledata,setLifecycledata] = useState(dt) 
    useEffect(()=>{         
        if(!sliderval){
            setSliderval((HandleData().filter(el => el.checked === true).length-1) * 25)
            if(setLifecyclevalue ){
                setLifecyclevalue(HandleData().filter(el => el.checked === true).length-1)
            }
          
        }

        if(lifecycledata === dt){
            setLifecycledata(HandleData) 
            console.log(HandleData,'HandleData')
        }

        if(setLstatus !== undefined){
            if(HandleData().filter(el => el.checked === true).length !==0){
                setLstatus(true)
            }else(
                setLstatus(false)
            )
           
        }  
    },[sliderval,lifecycledata])
  
    const handleLifecycle=(e)=>{
        const l=lifecycledata.length
        let t=lifecycledata
        const v=t[e].checked
     
        if(v){
            for(let i=e+1; i<=l-1; i++){
                t[i].checked=!v
            }
        }else{
            for(let i=e; i>=0; i--){
                t[i].checked=!v
                } 
        }
        if(windowGlobal){
        localStorage.setItem('lifecycledata',JSON.stringify(t))
        }
         setLifecycledata([...t])
         setSliderval(e*25)
         if(setLifecyclevalue){
            setLifecyclevalue(e)
         }
       
         
        
    }
    return(
        <div className={`cat-filter-data ${className ? className : ``}`}>
            <ul>
                {lifecycledata.map((d,i)=>{
                    return(
                        <li key={i} className={d.checked ? 'active' : ''} onClick={()=>handleLifecycle(i)}>
                        <span className="li-img">
                            <img src={d.img}/>
                            </span>
                        <p>
                        <span>{d.name}</span>
                            {d.desc}
                        </p>
                        <p className="filter-name-mbl"><span>{d.name}</span></p>
                        </li>
                    )
                })}
               
            </ul>
            <div className="sliders  row">
                <div className="col-sm-12">
                    <div className="bootstrap-slider">
                        <div className="slider slider-horizontal">
                        <div className="slider-track" >
                            <div className="slider-selection" style={{left: `0%`, width: `${sliderval === 25 ? sliderval+5 : sliderval === 75 ? sliderval - 5 : sliderval}%`}}></div>
                            <div className="slider-track-high" style={{right: `0%`, width: `${sliderval === 25 ? 70 : sliderval === 75 ? 30 : 100-sliderval}%`}}></div>
                        </div>
                        <label 
                        className="slider-handle max-slider-handle round" 
                        style={{left: `${sliderval === 25 ? sliderval+5 : sliderval === 75 ? sliderval - 5 : sliderval}%`}}/>
                        <input id="slider_inputtt" type="range" defaultValue={sliderval} step="25" min="0%" max="100%" onChange={(e)=>handleLifecycle(e.target.value/25)}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CompanyLifeCycle