import React,{useState, useEffect} from 'react'
import NextQuestionCta from './next-question-cta'
import tools from '../../assets/images/tools.svg'
import servers from '../../assets/images/servers.svg'
import version_control from '../../assets/images/version_control.svg'
import database from '../../assets/images/databse.svg'
import backend from '../../assets/images/backend.svg'
import frontend from '../../assets/images/frontend_icon.svg'
import nerdy from '../../assets/images/icons/nerdy_40x40.gif'

const QuestionSlide3 = ({activeSection, setActiveSection, show}) =>{
    const [dataupdated, serDataupdated] = useState(false)
    const [techstacklist, setTechstacklist] = useState(null)
    const [scrolled,setScrolled]=useState(false)

    const [techstackdata,setTechstackdata] = useState([
        {listname:'Frontend'
        ,listicon:frontend, 
        selected:false,
        listdata:[{itemname:'HTML',selected:false}, 
                {itemname:'HTML5',selected:false}, 
                {itemname:'Javascript',selected:false}, 
                {itemname:'TypeScript',selected:false}, 
                {itemname:'JQuery',selected:false}, 
                {itemname:'CSS3',selected:false}, 
                {itemname:'Actionscript',selected:false}, 
                {itemname:'Angular.js',selected:false}, 
                {itemname:'React',selected:false}, 
                {itemname:'Vue.js',selected:false}, 
                {itemname:'Bootstrap',selected:false}, 
                {itemname:'Material UI',selected:false}, 
                {itemname:'Foundation',selected:false}, 
                {itemname:'Semantic UI',selected:false}, 
                {itemname:'Materialize',selected:false},
                ]},
        {listname:'Backend',
        listicon:backend, 
        selected:false,
        listdata:[
                {itemname:'PHP',selected:false}, 
                {itemname:'.Net',selected:false}, 
                {itemname:'Ruby on Rails',selected:false}, 
                {itemname:'Angular2+',selected:false}, 
                {itemname:'Node.js',selected:false}, 
                {itemname:'Java',selected:false}, 
                {itemname:'Python',selected:false}, 
                {itemname:'SQL',selected:false},
            ]},
        {listname:'Database',
        listicon:database, 
        selected:false,
        listdata:[
                {itemname:'MySQL',selected:false},
                {itemname:'MongoDB',selected:false},
                {itemname:'CouchDB',selected:false},
                {itemname:'MS SQL',selected:false},
                ]},
        {listname:'Version Control',
        listicon:version_control, 
        selected:false,
        listdata:[
                {itemname:'Git',selected:false}, 
                {itemname:'Grunt',selected:false},
                {itemname:'Xdebug',selected:false}, 
                {itemname:'Subversion',selected:false}, 
                {itemname:'Webpack',selected:false},
                            ]},
        {listname:'Servers & Hosting',
        listicon:servers, 
        selected:false,
        listdata:[
                {itemname:'AWS',selected:false}, 
                {itemname:'Azure',selected:false},
                {itemname:'Apache',selected:false}, 
                {itemname:'Nginx',selected:false},
                {itemname:'Hostgator',selected:false},
                ]},
        {listname:'PM Tools',
        listicon:tools, 
        selected:false,
        listdata:[
                {itemname:'Youtrack',selected:false}, 
                {itemname:'Jira',selected:false}, 
                {itemname:'Basecamp',selected:false}, 
                {itemname:'Trello',selected:false}, 
                {itemname:'Redmine',selected:false}, 
                {itemname:'Teamwork',selected:false}, 
                {itemname:'Asana',selected:false}, 
                {itemname:'Pivotal Tracker',selected:false},
            ]}
    ])

    const handleSelect = (e) =>{
        if(Array.isArray(e.selecteditem)){
            let olddata = techstackdata
            olddata.forEach(dt =>{
                if(dt.listname === e.listname){
                    const status = !dt.selected
                    dt.selected = status
                    dt.listdata.forEach(ft=>{
                        ft.selected = status
                    })
                }
            })
            setTechstackdata([...olddata])
            if(window){
                localStorage.setItem('techstackdata',JSON.stringify(olddata))
            }
            
        }else if(Number.isInteger(e.selecteditem)){
            let olddata = techstackdata
            olddata.forEach(dt =>{
                if(dt.listname === e.listname){ 
                   dt.listdata[e.selecteditem].selected = !dt.listdata[e.selecteditem].selected
                }
            })
            setTechstackdata([...olddata])
            if(window){
                localStorage.setItem('techstackdata',JSON.stringify(olddata))
            }
        }
    }
    const handleTechList = (e) =>{
        if(techstacklist === e){
            setTechstacklist(null)
        }else{
            setTechstacklist(e)
        }
    }
    useEffect(()=>{
        if(window){
            if(localStorage.getItem('techstackdata') && !dataupdated){
                const ltsd = JSON.parse(localStorage.getItem('techstackdata'))
                setTechstackdata([...ltsd])
                serDataupdated(true)
            }
            if(show && !scrolled){
            window.scrollTo(0,0)
            setScrolled(true)
            }
            if(!show){
                setScrolled(false)
            }
        }
    },[techstacklist,show])
    return(
        <div className={`question-slide-3 question-slide secondspop`} style={{display:show ? `block` : `none`}}>
        <div className="wrapper main-container">
        <div className="listing-header align-center">
        <div className="small-wrapper" style={{minHeight:`182px`}}>
        <a href="#" className="slide-back" onClick={()=>setActiveSection(activeSection -1)}>
            <i className="fas fa-chevron-left"></i> Previous question</a>
        <p className="default">
            <label>
                <img src={nerdy} alt="nerdy"/>
            </label>If you know one or more of your tools or programming languages, then please{' '} 
            <strong className="green-box">
                <strong className="home-slide3">let us know</strong> <span> 
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                    viewBox="0 0 245 100"> 
                    <polygon fill="#66C293" points="0,0 245.4,12.5 236.4,100.6 0,100.6 "></polygon> 
                    </svg> 
                </span> 
            </strong>{' '}your{' '}
            <strong className="green-box">
                <strong className="home-slide3">technology stack</strong>
            <span> 
            <svg xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 245 100"> 
            <polygon 
            fill="#66C293" 
            points="0,0 245.4,12.5 236.4,100.6 0,100.6 "></polygon> 
            </svg> </span> 
            </strong>{' '}
            and don't worry this question is not obligated, if you don't know this (yet) - just skip it:
            </p>
        </div>
        </div>

        <div className="lists-row">

        {techstackdata.map((el,i)=>{
            return(
            <div key={`tdata${i}`} className={`list-container ${el.listname.toLowerCase()}`}>
            <div className="list-title-block" onClick={()=>handleTechList(i)}>
            <img src={el.listicon} alt="frontend"/>
            <span className="list-title-block-text">{el.listname}</span>
            <i  className={`fa fa-chevron-${techstacklist === i ? `up`:`down`}`} aria-hidden="true"></i>
            </div>
            <ul style={{display:techstacklist === i ? `block`:``}}>
            <li className={`list-title ${el.selected ? `li-active` : null}`} 
            onClick={()=> handleSelect({listname:el.listname,selecteditem:el.listdata})}>
                <span>{el.listname}</span>
                </li>
           {el.listdata.map((ldt,i)=>{
           return(<li key={`ldt${i}`} 
           className={`list-item-single ${ldt.selected ? `li-active` : null}`} 
           onClick={()=> handleSelect({listname:el.listname,selecteditem:i})}>
               <span>{ldt.itemname}</span>
               </li>)
           })}
            
           
            </ul>
            </div>
            )
        })}
        
        
        
        </div>
        <NextQuestionCta
           submit_btn_text={`Continue`}
           onClick={()=>setActiveSection(activeSection+1)}
           />
        </div>
        </div>
    )
}
export default QuestionSlide3