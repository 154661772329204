import React from 'react'
import smirk from '../../assets/images/icons/smirk_40x40.gif'
const NextQuestionCta = ({btnstatus,btntext,nothnxtext,submit_btn_text,submitclass,onClick,nothnxicon,nothanksredirect}) =>{
    return(
        <div className="next-ques-cta">
        {submit_btn_text && <span type={ `submit`} className={`${!submitclass ? `submit_btn` : submitclass} active`} onClick={onClick}>{submit_btn_text}</span>}
        {btntext && <span className={`submit_btn ${btnstatus ? `active`:``}`} onClick={onClick}>{btntext}</span>}
        <label className={btnstatus ? `disabled` : `unabled`}>
        <a href={nothanksredirect ? nothanksredirect : "#" } className="no_thanks_btn">
        <img src={!nothnxicon ? smirk : nothnxicon} alt="smirk"/> {' '}
        {nothnxtext}
        </a>
        </label>
       
        </div>
    )
}
export default NextQuestionCta